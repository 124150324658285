import { lighten } from '@mui/material';
import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes} from '@mui/material/styles';

const defaultTheme = createTheme();

// A custom theme for this app
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode : 'light',
      /*
      primary: {
        main: '#FFD700',
      },
      secondary: {
        main: '#B87333',
      },
      */
      error: {
        main: red.A400,
      },
      primary : {
        main : '#ffbf00'
      },
      secondary : {
        // main : '#B87333' // Copper
        main : '#CB6D51' // Red Copper
        // main : '#FF8C00' // Dark Orange
      }
    },
    components : {
      MuiDivider : {
        styleOverrides: {
          root: {
            borderBottomWidth : '3px'
          },
        },
      },
      MuiTableCell : {
        styleOverrides : {
          body : {
            border        : `1px solid ${defaultTheme.palette.divider}`
          },
          head : {
            fontWeight    : 'bold', 
            background    : lighten(defaultTheme.palette.divider,0.5), 
            border        : `1px solid ${defaultTheme.palette.divider}`,
            borderBottom  : `2px solid black`
          }
        }
      }
    },
    typography: {
      fontSize    : 16, // Set your base font size here
      fontFamily  : 'Open Sans, sans-serif',
      fontWeight  : 200,
      h1: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 650,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      h2: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 600,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      h3: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 550,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      h4: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 500,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      h5: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 450,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      h6: {
        fontFamily      : 'Montserrat, sans-serif',
        fontWeight      : 400,
        marginTop       : defaultTheme.spacing(2),
        marginBottom    : defaultTheme.spacing(2)
      },
      caption : {
        fontWeight      : 600
      }
    },
  })
);

export default theme;