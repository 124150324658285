import React                from 'react';
import { ThemeProvider }    from '@mui/material/styles';
import theme                from './src/theme';

import './src/styles/global.css';

// 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
const FONTS = [
    'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap',
    'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap',
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'
]

export const onInitialClientRender = () => {
    FONTS.forEach((href) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    }) 
};

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={theme}>
        {element}
    </ThemeProvider>
);